import common from "@/common/mixins/common.js";
import notice from "@/api/admin/notice";

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
import { quillEditor } from 'vue-quill-editor'
 

// 工具栏
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{'header': 1}, {'header': 2}],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}], // outdent/indent
  [{'direction': 'rtl'}],
  [{'size': ['small', false, 'large', 'huge']}],
  [{'header': [1, 2, 3, 4, 5, 6, false]}],
  [{'font': []}],
  [{'color': []}, {'background': []}], // dropdown with defaults from theme
  [{'align': []}],
  [{'clean': '清除'}], // remove formatting button
  // ['link', 'image', 'video']
  ['image','link']
]


export default {
  name: "notice",
  components: {
    quillEditor
  },
  mixins: [common],
  data() {
    return {
      addNoticeBool: false,
      add: true,
      editid: 1,
      noticeForm: {
        content: "",
        content_en:"",
        shot_title:"",
        shot_title_en:"",
        uploadIconImgList: [], // 图片
      },
      uploadIconImgList:[], //上传成功的图片
      UploadReadyIconImgList:[],//待上传的图片
      uploadImgLimit: 1, //图片上传限制数量
      acceptImgType:["bmp","jpg","jpeg","png","webp"],//图片格式限制
      // 预览图片
      dialogImageUrl: '',
      dialogVisible: false,
      // 分页
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      noticeData: [],
      disabled: false,
      // 图片上传处理
      loading: false, // 加载loading
      uploadImgConfig: { // 图片上传配置 - 若不配置则使用quillEditor默认方式，即base64方式
        uploadUrl: this.$root.DownUrl+'/a1/upload/file', // 图片上传地址
        uploadMultipartUrl: this.$root.DownUrl+'/a1/upload/multFile', // 多图片上传地址
        maxSize: 20, // 图片上传大小限制，默认不超过20M
        name: 'file' // 图片上传字段
      },
      // 图片上传POST地址
      headerObj: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      headerObjEN: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      editorOption: {
        placeholder: '',
        theme: 'snow', // or 'bubble'
        modules: {
          clipboard: {
            // 粘贴版，处理粘贴时候带图片
            matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                 // upload点击上传事件
                 document.querySelector('.avatar-uploader input').click()
                } else {
                 this.quill.format('image', false)
                }
               }
            }
          }
        }
      },
      editorOptionEN: {
        placeholder: '',
        theme: 'snow', // or 'bubble'
        modules: {
          clipboard: {
            // 粘贴版，处理粘贴时候带图片
            matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                 // upload点击上传事件
                 document.querySelector('.avatar-uploaderEN input').click()
                } else {
                 this.quill.format('image', false)
                }
               }
            }
          }
        }
      },
      // 新网页地址
      newUrl:""
    };
  },
  watch: {
    addNoticeBool(val) {
      if (!val)
        this.noticeForm={
          content: "",
          content_en:"",
          shot_title:"",
          shot_title_en:"",
          uploadIconImgList:[]
        };
        this.uploadIconImgList = [],
        this.UploadReadyIconImgList = []
    },
  },
  created() {
    this.getNotice();
  },
  methods: {

    // 禁用掉粘贴
    handleCustomMatcher(node, Delta) {
        let ops = []
        Delta.ops.forEach(op => {
          // console.log(op.insert.image)
          if(typeof op.insert.image != "undefined" ){
            if(op.insert.image.indexOf("base64") > -1){
              this.$message({
                message:'不允许粘贴图片,请手动上传',
                type:'warning'
              })
            }else{
              ops.push({
                // insert: op.insert,
                ...op
              })
            }
          } else{
            ops.push({
              // insert: op.insert,
              ...op
            })
          }
        })
        Delta.ops = ops
        return Delta
      },

    // 上传商品图片成功回调
    uploadSuccess(res) {
      var dt = res
      let quill = this.$refs.myQuillEditor.quill
      // 如果上传成功
      if (dt.error == 0 && dt.data !== null) {
        // 转移图片
        notice.moveTestImg({url: dt.data}).then((res) => {
          this.newUrl = res.data.data
          // console.log(this.newUrl)
          // if ( res.data.error === 0) {
          // } else {
          //   this.$message({
          //     type: 'info',
          //     message: '图片上传失败！'
          //   })
          // }
        }).then(()=>{
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片 dt.url为服务器返回的图片地址
          quill.insertEmbed(length, 'image', this.newUrl)
          // 调整光标到最后
          quill.setSelection(length + 1)
        })
       
      } else {
       this.$message.error('图片插入失败')
      }
      // loading加载隐藏
      this.hideLoading()
    },

    // 上传商品图片成功回调
    uploadSuccessEN(res) {
      var dt = res
      let quill = this.$refs.myQuillEditorEN.quill
      // 如果上传成功
      if (dt.error == 0 && dt.data !== null) {
        // 转移图片
        notice.moveTestImg({url: dt.data}).then((res) => {
          this.newUrl = res.data.data
          // console.log(this.newUrl)
          // if ( res.data.error === 0) {
          // } else {
          //   this.$message({
          //     type: 'info',
          //     message: '图片上传失败！'
          //   })
          // }
        }).then(()=>{
            // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片 dt.url为服务器返回的图片地址
          quill.insertEmbed(length, 'image', this.newUrl)
          // 调整光标到最后
          quill.setSelection(length + 1)
        })
      } else {
       this.$message.error('图片插入失败')
      }
      // loading加载隐藏
      this.hideLoading()
    },

    // 隐藏
    hideLoading(){
      this.loading = false
    },

    // 文件上传失败时的钩子
    onError (file, fileList) {
      this.hideLoading()
      this.$message.error('上传失败')
    },

    // el-文件上传组件
    onBeforeUpload (file) {
      this.loading = true
      let acceptArr = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
      const isIMAGE = acceptArr.includes(file.type)
      const isLt1M = file.size / 1024 / 1024 < this.uploadImgConfig.maxSize
      if (!isIMAGE) {
        this.hideLoading()
        this.$message.error('只能插入图片格式!')
      }
      if (!isLt1M) {
        this.hideLoading()
        this.$message.error(`上传文件大小不能超过 ${this.uploadImgConfig.maxSize}MB!`)
      }
      return isLt1M && isIMAGE
    },

    //改变条数
    handleRenewRenewSizeChange(val) {
      this.pageInfo.pagesize=val;
      this.getNotice();
    }, //翻页
    handleChangeCurrentPage(val) {
      this.pageInfo.page=val;
      this.getNotice();
    },
    handleAddNotice() {
      this.add=true;
      this.addNoticeBool=true;
    },
    submitNotice() {
      const params=this.formatParams();
      notice
        .add(params)
        .then((res) => {
          res.data;
          this.$message.success("创建成功");
          this.getNotice();
          this.addNoticeBool=false;
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
    editNotice() {
      let params=this.formatParams();
      params.id=this.editid;
      notice.edit(params).then(() => {
        this.$message.success("修改成功");
        this.getNotice();
        this.addNoticeBool=false;
      });
    },
    delNotice(row) {
      notice.del(row.id).then(() => {
        this.$message.success("删除成功");
        this.getNotice();
      });
    },
    getNotice() {
      notice.get(this.pageInfo.page, this.pageInfo.pagesize, -1, -1).then((res) => {
        this.noticeData=res.data.data.data_list?res.data.data.data_list:[];
        this.pageInfo.total=res.data.data.total;
      });
    },
    handleEdit(type, r) {
      switch (type) {
        case "edit":
          var row=JSON.parse(JSON.stringify(r));
          this.add=false;
          this.addNoticeBool=true;
          this.editid=row.id;
          this.clearImages() //清空旧数据
          // 表单数据
          this.noticeForm.content=row.content;
          this.noticeForm.content_en = row.content_en;
          this.noticeForm.shot_title = row.shot_title;
          this.noticeForm.shot_title_en = row.shot_title_en;
            // 判断是否存在图片
          if (row.icon_img_url !=="") {
            const ImgNameArr = row.icon_img_url.split('/')
            setTimeout(() => {
              this.uploadIconImgList.push({
                name:ImgNameArr[ImgNameArr.length - 1],
                url:row.icon_img_url
              })
            }, 100);
            this.noticeForm.uploadIconImgList.push(row.icon_img_url)
          }
          console.log(this.uploadIconImgList)
          var start=row.startTime > 0 ? new Date(row.startTime * 1000) : "";
          var end=row.startTime > 0 ? new Date(row.endTime * 1000) : "";
          this.noticeForm.time=[start, end];
          break;
        case "del":
          this.$confirm("此操作将永久删除, 是否继续?", "删除", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.delNotice(r);
              this.getNotice()
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
      }
    },
    
    
    formatParams() {
      return {
        content: this.noticeForm.content,
        content_en:this.noticeForm.content_en,
        shot_title:this.noticeForm.shot_title,
        shot_title_en:this.noticeForm.shot_title_en,
        upload_icon_img_list:this.noticeForm.uploadIconImgList
      };
    },

   // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓图片····相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
   handleExceed(files, fileList) {
    this.$message.warning(`限制选择`+this.uploadImgLimit+`个文件，本次选择了 `+files.length+`个文件`)
  },
  //清空图片数据
  clearImages(){
    this.uploadIconImgList = [] 
    this.noticeForm.uploadIconImgList = []
  },
  // 过滤重复
  filterRepetition(arr) {
    let arr1 = []; //存id
    let newArr = []; //存新数组
    for (let i in arr) {
      if (arr1.indexOf(arr[i].name) == -1) {
        arr1.push(arr[i].name);
        newArr.push(arr[i]);
      }
    }
    return newArr;
  },
  // 修改 存放要上传的文件列表
  handleFileChange(file, fileList) {
    let isNotAlowUpload = false //不允许上传
    let arr = this.filterRepetition(fileList);
    if (arr.length !== fileList.length) {
      isNotAlowUpload = true
      this.$message("上传重复文件，已过滤重复文件");
    }
    //文件判断
    for (const key in arr) {
      if (typeof(arr[key].raw)!=="undefined") {
        // 判断是否超出限制大小10M
       if(arr[key].size / 1024 / 1024 > 5){
          isNotAlowUpload = true
          this.$message.error("每个上传图像的大小不应超过5M");
        } 
        // 判断格式是否正确
        let typeExt = arr[key].raw.type
        let typeExtBool = false
        for (const key in this.acceptImgType) {
          if (typeExt.indexOf(this.acceptImgType[key])>-1) {
            typeExtBool = true
          }
        }
        if (typeExtBool===false) {
          isNotAlowUpload = true
          this.$message.error("请上传以下格式的图像:"+this.acceptImgType.join("/"));
        }
      } else {
        continue //如果不是新选择的文件，跳过检验
      }
    }

    // 不超过限制才上传
    if (isNotAlowUpload === false) {
      this.UploadReadyIconImgList = arr;
      this.debounce(this.submitUpload, 500)
    } else {
      // 清空不符合缓存
      let uploadImgEl = this.$refs.uploadImgEl.uploadFiles
      for (const key in uploadImgEl) {
        if (uploadImgEl[key].status ==="ready") {
          this.$refs.uploadImgEl.uploadFiles.splice(key,1)
        }
      }
    }


  },
  // element上传多个文件时，会把每个文件做个单独请求
  // 这里的方法是请求最后一次
  debounce(fn, waits) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      fn.apply(this, arguments); // 把参数传进去
    }, waits);
  },

  // 确定
  async submitUpload() {
    if (this.UploadReadyIconImgList.length === 0) {
      this.$message.error("请上传文件");
      return;
    }

    let formData = new FormData(); //  用FormData存放上传文件
    this.UploadReadyIconImgList.forEach((file) => {
      formData.append("file", file.raw); // file.raw
    });

    // 确定上传 把在上传列表里的文件 合并到formData里面传给后台
    const loading = this.$loading({
      lock: true,
      text: 'Uploading, please wait',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    // 使用多图片上传接口
    let res = await notice.uploadApi(this.uploadImgConfig.uploadMultipartUrl,formData,this.headerObj.Authorization);
    if (res.data.error === 0) {
      let data = res.data.data
      for (const key in data) {
        const ImgNameArr = data[key].split('/')
        this.uploadIconImgList.push({
          name:ImgNameArr[ImgNameArr.length - 1],
          url:data[key]
        })
        this.noticeForm.uploadIconImgList.push(data[key])
      }
    }
    loading.close()
  },

  // 预览图片
  handlePreview(file) {
      this.dialogImageUrl=file.url;
      this.dialogVisible=true;
  },

  /**
   * 删除图片
   */
  handleDeleteImg(postData) {
    const loading = this.$loading({
      lock: true,
      text: 'Deleting, please wait',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    notice.deleteIconImg(postData).then((res) => {
        const error  = res.data.error
        if (error === 0) {
        this.$message({
            type: 'success',
            message: 'Successfully deleted!'
        })
        } else {
        this.$message({
            type: 'info',
            message: 'undelete'
        })
        }
    }).finally(()=>{
      this.getNotice()
      loading.close()
    })
  },

  /* 删除未提交表单的图片 */
  handleDeleteTestImg(imgArr) {
    const loading = this.$loading({
      lock: true,
      text: 'Deleting, please wait',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    notice.DeleteIconTestImg({ img_arr: imgArr }).then((res) => {
        const  error  = res.data.error
        if (error === 0) {
        this.$message({
            type: 'success',
            message: 'Successfully deleted!'
        })
        } else {
        this.$message({
            type: 'info',
            message: 'undelete'
        })
        }
    }).finally(()=>{
      this.getNotice()
      loading.close()
    })
  },

  /* 删除表单的图片 */
  handleDeleteFormImg(file_url){
    for (const key in this.noticeForm.uploadIconImgList) {
      if(this.noticeForm.uploadIconImgList[key] === file_url){
        this.noticeForm.uploadIconImgList.splice(key, 1) // 移除表单图片
      }
    }
  },


  /**
   * 删除图片之前确认
   */
  handleImgBeforeRemove(file, fileList) {
    const bv = false // 阻止直接移除，采用手动移除
    if (file.url.indexOf('/uploads/test/') > -1) {
      var delArr = []
      for (let index = 0; index < this.uploadIconImgList.length; index++) {
        if (this.uploadIconImgList[index].url === file.url) {
          this.uploadIconImgList.splice(index, 1) // 移除展示的图片
          delArr.push({ imgSrc: file.url })
        }

      }
      if (delArr.length > 0) {
        this.handleDeleteTestImg(delArr) // 删除test路径的图片
      }
    } else {
      let tipText = "此操作将永久删除该文件, 是否继续?"
      this.$confirm(tipText, 'prompt', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then((res) => {
          // 移除展示的数组
          for (let index = 0; index < this.uploadIconImgList.length; index++) {
            if (this.uploadIconImgList[index].url === file.url) {
              this.uploadIconImgList.splice(index, 1) // 移除展示的图片
            }
          }
          // 删除图片
          var delArr = []
          delArr.push({imgSrc:file.url})
          this.handleDeleteImg({img_arr: delArr})
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'undelete'
          })
        })
    }
    // 移除表单的数据
    this.handleDeleteFormImg(file.url)
    // 阻止删除
    return bv
  }, 
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑图片····相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/


    
  },
};
