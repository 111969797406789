import axios from "axios";

export default {
  get: (page, pagesize) => {
    const params = {
      page: page,
      pagesize: pagesize,
    };
    return axios.get("/a1/notice", { params: params });
  },
  add: (params) => {
    return axios.post("/a1/notice", params);
  },
  edit: (params) => {
    return axios.put("/a1/notice", params);
  },
  del: (id) => {
    return axios.delete("/a1/notice/"+id);
  },
  moveTestImg:(params)=>{
    return axios.put("/a1/notice/moveTestImg", params);
  },
  DeleteIconTestImg:(params)=>{
    return axios.put("/a1/notice/deleteIconTestImg", params);
  },
  deleteIconImg:(params)=>{
    return axios.put("/a1/notice/deleteIconImg", params);
  },
  uploadApi:(url,data,token) =>{
    return axios.post(url,data,{headers: {'Authorization':token }});
  }
};
