<template>
    <div class="banner">
      <div class="banner-header">
        <el-button @click="handleAddNotice">添加公告</el-button>
        <el-dialog title="添加公告" :visible.sync="addNoticeBool" width="75%">
          <el-upload
            class="avatar-uploader"
            :action="uploadImgConfig.uploadUrl"
            name="file"
            :headers="headerObj"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :on-error="onError"
            :before-upload="onBeforeUpload">
          </el-upload>
          <el-upload
            class="avatar-uploaderEN"
            :action="uploadImgConfig.uploadUrl"
            name="file"
            :headers="headerObjEN"
            :show-file-list="false"
            :on-success="uploadSuccessEN"
            :on-error="onError"
            :before-upload="onBeforeUpload">
          </el-upload>
          <el-form label-width="100px">
            <el-form-item label="预览图">
              <el-upload class="upload-demo"
                ref="uploadImgEl"
                :action="uploadImgConfig.uploadMultipartUrl"
                :on-preview="handlePreview"
                list-type="picture-card"
                :multiple="false"
                :auto-upload="false"
                :file-list="uploadIconImgList"
                :on-exceed="handleExceed"
                :limit="uploadImgLimit"
                :on-change="handleFileChange"
                :before-remove="handleImgBeforeRemove"
                >
                <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
              </el-upload>
              <!--            图片放大预览-->
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                  <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="noticeForm.shot_title"></el-input>
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="100px">
            <el-form-item label="标题(英文)">
              <el-input v-model="noticeForm.shot_title_en"></el-input>
            </el-form-item>
          </el-form>      
          <el-form ref="form" label-width="100px" style="height:600px;">
            <el-form-item label="内容（中文）">
              <quill-editor 
                  v-model="noticeForm.content" 
                  ref="myQuillEditor"
                  style="height:450px;"
                  :options="editorOption">
              </quill-editor>
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="100px" style="height:600px;">
            <el-form-item label="内容（英文）">
              <quill-editor 
                  v-model="noticeForm.content_en"
                  ref="myQuillEditorEN" 
                  :options="editorOptionEN"
                  style="height:450px;">
              </quill-editor>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addNoticeBool = false">取 消</el-button>
            <el-button type="primary" @click="submitNotice" v-if="add">提 交</el-button>
            <el-button type="primary" @click="editNotice" v-if="!add">编 辑</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="banner-content">
        <div class="banner-content-list">
          <el-table :data="noticeData">
            <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>
            <el-table-column prop="shot_title" label="公告标题">
              <template v-slot="{row}">
                <div v-html="row.shot_title"></div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot="{ row }">
                <el-button type="primary" plain @click="handleEdit('edit', row)">编辑</el-button>
                <el-button type="primary" plain @click="handleEdit('del', row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div ref="banner-footer" class="banner-footer" v-if="noticeData.length > 0">
          <el-pagination
            :key="1"
            :current-page="pageInfo.page"
            :page-size="pageInfo.pagesize"
            :page-sizes="[10, 20, 50, 100, 200]"
            layout="total,sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
            @size-change="handleRenewRenewSizeChange"
            @current-change="handleChangeCurrentPage"
            background
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import notice from "./js/index";
  
  export default notice;
  </script>
  
  <style scoped lang="scss">
  @import "css/index";
  </style>
  
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  </style>
  